.btn {
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 2px #ededed;
}

.nameCollaborator {
  color: #071c1f;
  font-size: 16px;
  margin: 8px 0 8px 0;
}

.description {
  margin-bottom: 8px !important;
  font-size: 14px !important;
  color: #212529 !important;
}

.collaboratorItem {
  padding: 40px 16px 40px 16px;
  border: 2px solid var(--border-color-11);
  margin-bottom: 0px;
  display: flex;
}
