.title {
  background-color: var(--ltn__secondary-color);
  padding: 8px 16px;
  justify-content: space-between;
  display: flex;
  padding-left: 24px;
}

.title button {
  min-height: 50px;
  text-align: justify;
  background-color: var(--ltn__secondary-color);
  border: none;
  color: #fff;
  font-size: 20px;
}

.title button i::before {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: #fff;
  border-radius: 50%;
}

nav ul {
  list-style: none;
  color: #000;
}

nav ul li i {
  padding-right: 16px;
}

.text {
  padding: 0 0 16px 0;
}

li a {
  text-decoration: none;
  font-weight: 700;
}

.contactButton {
  background-color: #000;
  border: none;
  min-width: 100%;
  color: #fff;
  padding: 8px 0;
  border-radius: 4px;
}

/* li:last-child {
  display: flex;
  justify-content: center;
  margin: 0;
} */

li:last-child button {
  background-color: #fff;
  border: none;
  font-weight: 700;
}
