h4 {
  color: #071c1f;
}

.selected {
  background-color: var(--ltn__secondary-color);
  color: #fff;
  border-radius: 4px;
  margin-bottom: 10px;
}

.selected:hover {
  color: #fff;
  background-color: var(--ltn__secondary-color);
}

.button {
  margin-bottom: 10px;
  width: 300px;
  background-color: #d9d9d9;
  color: var(--white);
  border-radius: 4px;
}

.button:hover {
  color: var(--white);
  background-color: #d9d9d9;
  filter: brightness(0.8);
}
