h5 {
  color: #000;
}

p {
  color: #5c727d;
  font-size: 14px;
  font-weight: 100;
}

.description {
  margin: 8px 0 0 0;
}

input:disabled,
textarea:disabled {
  background-color: #f5f5f5;
}
